$(document).ready(function () {
    var header = $("#main-header");

    // HEADER - MENU
    header.find(".header-mobile-menu").on("click", function (e) {
        if ($(this).find(".icon-menu").css("display") == "inline") {
            e.preventDefault();
            $("body").toggleClass("no-scroll").toggleClass("menu");
            $(".fixed-bg").addClass("menu").fadeToggle();
            $(this).parents("#main-header").find(".header-bar").fadeToggle(300).toggleClass("toggle");
        }
    });

    header.find(".lang").find(".active").on("click", function () {
        $(this).toggleClass("hover");
        $(this).next(".list").slideToggle();
    });

    var menu = header.find(".nav-menu").html();

    $(menu).appendTo(".header-bar-nav");

    $(".fixed-bg, .header-bar .icon-close").on("click", function () {
        $("body").toggleClass("no-scroll").toggleClass("menu");
        $(".fixed-bg.menu").fadeToggle().removeClass("menu");
        $("#main-header").find(".header-bar").fadeToggle(300).toggleClass("toggle");
    });

    var lang = $("html").attr('lang');
    var start_date = $("#f-arrival");
    var end_date = $("#f-departure");

    start_date.datetimepicker({
        dayOfWeekStart: 1,
        lang: lang,
        timepicker: false,
        scrollMonth: false,
        format: 'Y-m-d',
        minDate: '-1970/01/01',
        closeOnDateSelect: true,
        scrollInput: false,
        onSelectDate: function (ct) {
            ct.setDate(ct.getDate() + 1);
            $("#f-departure").val(ct.dateFormat('Y-m-d'));
        }
    });
    start_date.prev(".icon").on("click", function () {
        $(this).next(".date").datetimepicker('show');
    });

    end_date.datetimepicker({
        dayOfWeekStart: 1,
        lang: lang,
        timepicker: false,
        scrollMonth: false,
        format: 'Y-m-d',
        formatDate: 'Y-m-d',
        minDate: '-1970/01/01',
        closeOnDateSelect: true,
        scrollInput: false,
        onShow: function (ct) {
            var date = new Date(start_date.val());
            date.setDate(date.getDate() + 1);
            this.setOptions({
                minDate: date.dateFormat('Y-m-d')
            });
        }
    });
    end_date.prev(".icon").on("click", function () {
        $(this).next(".date").datetimepicker('show');
    });


    $(".sp-featuring.type-map").find(".item-content").on("click", function () {
        $(this).fadeOut("slow").closest(".sp-featuring").addClass("mod--active");
    });
    var sp_offers_detail = $("#sp-offers").find(".item-title");
    if (!sp_offers_detail.attr("href")) {
        sp_offers_detail.on("click", function () {
            $(this).find(".item-heading").find(".btn").toggleClass("hide");
            $(this).next(".item-content").find(".item-text").slideToggle("slow").toggleClass("active");
        });
    }

    $(".sp-text").find(".sp-text-inner").find("hr").replaceWith("<span class='heading-line-big'></span>");

    // FOOTER - NEWLOGIC
    $(document).on('click', '.footer-logo', function (e) {
        e.preventDefault();
        $('#copyrightspopup').fadeToggle('slow');
    });


    $('.detail-image-slider').find('.slider-inner').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000
    });

    $('.hp-slides.slide').find('.hp-slides-inner').each(function () {
        $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            var count = $(this).parents(".hp-slides.slide").find(".count");
            count.find(".min").text(i);
        });
        $(this).slick({
            //lazyLoad: 'progressive',
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 6500,
            responsive:
                    [
                        {
                            breakpoint: 769,
                            settings: "unslick"
                        }
                    ]
        });
    });
    var $offers_slider = $(".hp-offers.slide").addClass("is-slide").find('.hp-offers-inner')

    $offers_slider.on("init", function(event, slick, currentSlide, nextSlide){
        if(slick.slideCount < 3){
            $offers_slider.find(".slick-track").css("margin","0 auto");
        }
    });

    $offers_slider.slick({
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive:
                [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: "unslick"
                    }
                ]
    });

    $("#hp-gallery").find('.sub-heading-wrapper.slide').find('.sub-heading').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: false,
        variableWidth: true,
        responsive:
                [
                    {
                        breakpoint: 768,
                        settings: "unslick"
                    }
                ]
    });

    $(window).resize(nl_check_size);

    nl_hover_touch_unstick();

    $(window).scroll(nl_scroll_to);
    nl_scroll_to();

    $('.venobox').venobox();

    $("select").each(function () {
        var select_placeholder = $(this).attr("data-placeholder");
        $(this).select2({
            placeholder: select_placeholder
        });
        if ($(this).hasClass("error")) {
            $(this).next(".select2").addClass("error");
        }
        $(this).on("select2:open", function () {
            $(this).next(".select2").find(".select2-selection__arrow").addClass("icon-arrow-2-top");
        });
        $(this).on("select2:close", function () {
            $(this).next(".select2").find(".select2-selection__arrow").removeClass("icon-arrow-2-top");
        });
    });


    $(document).on('click', "[data-ajax='roomDetail']", function (e)
    {
        e.preventDefault();
        var $elem = $(this);

        $(".detail-rooms").fadeOut(300).remove();
        $("body").removeClass("no-scroll").removeClass("no-scroll-room");

        $.ajax({
            url: $elem.attr('href')
        }).done(function (data) {
            $("#body").prepend(data);
            $('.detail-image-slider').find('.slider-inner').slick(
                    {
                        lazyLoad: 'ondemand',
                        infinite: true,
                        speed: 300,
                        slidesToShow: 1,
                        autoplay: true,
                        autoplaySpeed: 5000
                    });
            $(".detail-rooms").addClass("fixed").hide().fadeIn(300);
            $(".fixed-bg").addClass("detail").fadeIn(300);
            $("body").addClass("no-scroll").addClass("no-scroll-room");
        });
    });

    $("#body").on("click", ".detail-close", function ()
    {
        $(".detail-rooms").fadeOut(300).remove();
        $(".fixed-bg").fadeOut(300).removeClass("detail");
        $("body").removeClass("no-scroll").removeClass("no-scroll-room");
    });

    $(document).on('click', '#hp-gallery [data-gallery]', function (e) {
        e.preventDefault();
        $('#hp-gallery [data-gallery]').removeClass('active');
        $(this).addClass('active');
        getHomepageGallery();
    });
    getHomepageGallery();

    var nl_lib_dialog = fn_lib_dialog();

    nl_lib_dialog.init("html");

    $("[data-showreel]").on("click",function () {
        var fn = $(".part_dialog_showreel");

        if (fn.length) {
            var fn_html = fn.clone();
            nl_lib_dialog.open(fn_html);
        }
    })
});

$(window).load(function () {

    $('.hp-visual-inner').slick({
        lazyLoad: 'progressive',
        infinite: true,
            speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
            pauseOnHover: false,
        cssEase: 'linear'
    });
});

function nl_switch_content(nl_switch_class) {
    $(".switch-content").hide();
    $(".switch-content.active").show();
    var button = $(nl_switch_class).find(".switch-nav").find(".switch-button");
    var content = $(nl_switch_class).find(".switch-area").find(".switch-content");
    content.each(function (n) {
        var button_class = ("item-" + n);
        $(this).addClass(button_class);
    });
    button.each(function (n) {
        var button_class = ("item-" + n);
        $(this).addClass(button_class);
        $(this).on("click", function (e) {
            e.preventDefault();
            $(this).closest(".switch-nav").find(".switch-button").removeClass("active").css("cursor", "pointer");
            $(this).addClass("active").css("cursor", "default");
            content.hide().removeClass("active");
            content.closest(".switch-area").find("." + button_class).fadeIn();
        });
    });
}

function nl_more_text(element, height) {
    $(element).each(function () {
        var text_length = $(this).prev(".text-inner").find(".text").height();
        //alert(text_length);
        if (text_length <= height) {
            $(this).hide();
        } else {
            $(this).show();
        }
    });
}

function nl_scroll_to() {
    var window_top = $(window).scrollTop();
    var div_top = $("#page").offset().top + 82;
    if (window_top > div_top) {
        $('.header').addClass('fixed');
    } else {
        $('.header').removeClass('fixed');
    }
}

function nl_check_size() {
    if ($("#main-header").find(".header-mobile-menu").find(".icon-menu").css("display") == "none") {
        $(".nav-menu").show();
        $("body.menu").removeClass("no-scroll");
        $(".fixed-bg.menu").fadeOut();
        $("#main-header").find(".header-bar").show().removeClass("toggle");
    } else {
        $("#main-header").find(".header-bar").hide().removeClass("toggle");
    }
}

function nl_hover_touch_unstick() {
    // Check if the device supports touch events
    if ('ontouchstart' in document.documentElement) {
        // Loop through each stylesheet
        for (var sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
            var sheet = document.styleSheets[sheetI];
            // Verify if cssRules exists in sheet
            if (sheet.cssRules) {
                // Loop through each rule in sheet
                for (var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
                    var rule = sheet.cssRules[ruleI];
                    // Verify rule has selector text
                    if (rule.selectorText) {
                        // Replace hover psuedo-class with active psuedo-class
                        rule.selectorText = rule.selectorText.replace(":hover", ":active");
                    }
                }
            }
        }
    }
}

var gallery_inner = $('#hp-gallery').find('.hp-gallery-inner');

function homepage_gallery() {
    $('.gallery-preview').removeClass('one').slick({
        slidesToShow: 3,
        variableWidth: true,
        infinite: false,
        centerMode: true,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: true,
        fade: false,
        asNavFor: '.gallery-nav'
    });
    $('.gallery-nav').removeClass('one').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 1,
        asNavFor: '.gallery-preview',
        dots: false,
        centerMode: false,
        focusOnSelect: true
    });
    $('.gallery-preview').slick('slickNext');
    setTimeout(function () {
        gallery_inner.removeClass("not-visible");
    }, 500);

    if ($('.gallery-preview .item').length < 3) {
        $('.gallery-preview').slick('unslick').addClass('one');
        $('.gallery-nav').slick('unslick').addClass('one');
    }
}

function getHomepageGallery() {
    var id = $('#hp-gallery').find('[data-gallery].active').data('gallery');

    gallery_inner.hide().addClass("not-visible");
    //$('.gallery-preview').slick('unslick');
    //$('.gallery-nav').slick('unslick');
    $.ajax({
        url: '/ajax/gallery/',
        data: {id: id}
    }).done(function (result) {
        gallery_inner.html(result);
        gallery_inner.hide().addClass("not-visible");
        gallery_inner.imagesLoaded(function () {
            gallery_inner.show();
            homepage_gallery();
            $('#hp-gallery .venobox').venobox();
        });
    });
}

$(function () {
    var $map = $('#google-map');

    if (!$map.length) {
        return;
    }

    var coords = $map.data('coords').split(/[\s,]+/);

    var mapOptions = {
        zoom: 17,
        scrollwheel: false,
        draggable: false,
        mapTypeControl: false,
        center: {
            lat: parseFloat(coords[0]),
            lng: parseFloat(coords[1])
        }
    };

    var map = new google.maps.Map($map[0], mapOptions);

    var marker = new google.maps.Marker({
        position: mapOptions.center,
        map: map,
        title: $map.data('title')
    });

    google.maps.event.addListener(map, 'click', function () {
        map.setOptions({
            scrollwheel: true,
            draggable: true
        });
    });
});

function fn_lib_dialog() {
    var dialog = $("[data-dialog]"),
        doc = $(document),
        body = $('body');

    function fn_lib_dialog_show(data,callback) {
        doc.find(".lib--dialog .part_dialog").remove();
        if (!doc.find(".lib--dialog").length) {
            body.addClass("mod--no-scroll").append('<div class="lib--dialog"><div class="elm_dialog_background"></div></div>');
        }
        doc.find(".lib--dialog").append(data).fadeIn(300);
        if (callback) {
            callback();
        }
    }

    function fn_lib_dialog_hide() {
        body.removeClass("mod--no-scroll");
        dialog.removeClass("mod--active");
        doc.find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            doc.find(".lib--dialog").remove();
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        doc.on('click', '[data-dialog]', function (e) {
            e.preventDefault();
            var el = $(this);
            el.addClass("mod--active");

            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: el.data('dialog')
            }).done(function(data) {
                fn_lib_dialog_show(data.dialog, callback);
            });
        });

        doc.on("click", ".elm_dialog_background, [data-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
